import React from 'react';
import { WhatsAppOutlined, MailOutlined } from '@ant-design/icons';
import agent from '../../assets/images/agent-card.svg'
import Button from '../buttons/Button';
const AgentCard = () => {
    return (
        <div className="max-w-sm  min-w-[300px] md:min-w-[370px] mt-4  w-full p-6 py-10 bg-white rounded-[30px] shadow_c_1 border border-theme_blue text-center">
            <img
                src={agent} // Replace with actual image URL
                alt="Daniel Pansky"
                className="w-24 h-24 mx-auto rounded-full border-4 border-white "
            />
            <h2 className="mt-4 text-3xl font-bold text-theme_yellow creato-f-b">Daniel Pansky</h2>
            <p className="text-theme_grey2 text-[20px]">LuxLife RE Agent</p>
            <p className="text-theme_grey2 text-[20px]">Global Advisor</p>
            <p className="mt-2 font-semibold text-black creato-f-b">Call or Text Me Anytime</p>
            <a href="tel:+13059886068">
            <p className="text-theme_yellow creato-f-m">(305) 988-6068</p>
            </a>


            <div className="flex flex-col gap-3 mx-4 mt-4">
            <a className='w-full' target='_blank' href="https://wa.link/q26x4b">
                <Button title={<div className="flex items-center justify-center gap-2 text-white   ">
                    <WhatsAppOutlined />
                    WhatsApp
                </div>}
                    className={'w-full rounded-lg py-3 creato-f-m'}
                />
    </a>

<a className='w-full' href="mailto:dpansky@luxlifemiamirealestate.com">
                <Button title={
                    <div className="flex items-center justify-center gap-2 text-white   ">
                    <MailOutlined />
                    Send Me Info
                </div>
                }
                className={'w-full rounded-lg creato-f-m py-3'}
                />
                </a>


            </div>
        </div>
    );
};

export default AgentCard;
