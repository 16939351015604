import React, { useEffect, useRef, useState } from 'react'
import ContactLayout from '../components/ui/layout/ContactLayout'
import ImageViewer from '../components/image-viewer/ImageViewer'
import image1 from '../assets/images/card1.png'
import image2 from '../assets/images/card2.png'
import image3 from '../assets/images/card3.png'
import image4 from '../assets/images/card1.png'
import image5 from '../assets/images/card2.png'
import bed from '../assets/images/bed.png'
import bath from '../assets/images/bath.png'
import area from '../assets/images/area.png'
import sec4Logo from '../assets/images/sec4_logo.svg'
import Button from '../components/buttons/Button'
import { IoIosArrowForward, IoIosShareAlt } from 'react-icons/io'
import { FaLocationDot } from 'react-icons/fa6'
import { BsCircleFill } from 'react-icons/bs'
import MapComponent from '../components/map/Map'
import AgentCard from '../components/cards/AgentCard'
import AnimationDiv from '../components/animation/AnimationDiv'
import Card from '../components/cards/Card'
import { cardVariants } from './New100Listing'
import { useNavigate, useParams } from 'react-router-dom'
import { getListingByID, getListingReferencePropertyData } from '../api/GET'
import { API_BASE_URL } from '../config'
import axios from 'axios'
import { message, Spin } from 'antd'
import { Helmet } from 'react-helmet'
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai'
import { isLoginHandle } from '../lib/helper'
import { useData } from '../context/DataContext'
import { PopupModal } from 'react-calendly'
import { toast } from 'react-toastify'
import { LoadingOutlined } from '@ant-design/icons'


const images = [
    image1, image2, image3, image4, image5
];

message.config({
    top: 90, // Header ke neeche position
    style: { zIndex: 11001 }, // Higher z-index for overlapping other elements
});
const SingleProperty = () => {
    const { id } = useParams()
    const [showPopup, setShowPopup] = useState(false);
    const { data } = useData();
    const navigate = useNavigate();

    const [allData, setallData] = useState({})

    const [isSaved, setIsSaved] = useState(allData?.is_saved === 1);
    const [loading, setLoading] = useState(false);

    const [properties, setProperties] = useState([])
    const fetchProperties = async () => {

        const data = await getListingReferencePropertyData(`PropertySubType.in=${allData?.PropertySubType}&UnparsedAddress=${allData?.City}`);
        if (Array.isArray(data?.data?.data)) {
            setProperties(data?.data?.data)
        }
    }
    useEffect(() => {

        fetchProperties()
    }, [allData])


    const handleBookAppointment = () => {
        if (!data?.isLogin) {
            toast.error('Please Login to Continue');
            setKeyData('isLoginModalOpen', true);
        } else {
            setShowPopup(true);

        }
    };

    useEffect(() => {
        if (id !== allData?.ListingID) {
            setallData({})
            const fetchData = async () => {
                const data = await getListingByID(id);

                if (data?.data && data?.data.length > 0 && data.data[0]) {
                    setallData(data?.data[0])
                }
            }
            fetchData()
        }
    }, [id])

    const toggleWishlist = async (loading, setLoading, isSaved, setIsSaved, ListingID) => {
        if (loading) return;

        setLoading(true);

        const token = JSON.parse(localStorage.getItem('user_data'))?.token; // Retrieve the token from localStorage
        const headers = {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        };

        try {
            if (!isSaved) {
                setIsSaved(true); // Mark as saved
                // API call to add to saved
                await axios.post(
                    `${API_BASE_URL}domainparmeter/saved-property/store`,
                    { listing_id: ListingID },
                    { headers } // Pass headers with the request
                );
            } else {
                setIsSaved(false); // Mark as unsaved
                // API call to remove from saved
                await axios.delete(
                    `${API_BASE_URL}domainparmeter/saved-property/${ListingID}`,
                    { headers } // Pass headers with the request
                );
            }
        } catch (error) {
            console.error('Error toggling wishlist:', error);
        } finally {
            setLoading(false);
        }
    };

    const overviewRef = useRef(null);
    const [isStuck, setIsStuck] = useState(true);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsStuck(entry.isIntersecting);
            },
            {
                rootMargin: '0px',
                threshold: 0.1, // Adjust this threshold as needed
            }
        );

        if (overviewRef.current) {
            observer.observe(overviewRef.current);
        }

        return () => {
            if (overviewRef.current) {
                observer.unobserve(overviewRef.current);
            }
        };
    }, []);
    useEffect(() => {
        setallData({});

        const fetchData = async () => {
            const data = await getListingByID(id);

            if (data?.data && data?.data.length > 0 && data.data[0]) {
                setallData(data?.data[0])
            }
        }
        fetchData()
    }, [])



    const { setKeyData } = useData()

    const handleCopyUrl = () => {
        const currentUrl = window.location.href; // Current URL fetch karna
        navigator.clipboard
            .writeText(currentUrl) // Clipboard mein URL copy karna
            .then(() => {
                message.success("URL copied successfully!"); // Success message show karna
            })
            .catch((error) => {
                message.error("Failed to copy URL."); // Error message show karna
                console.error("Copy failed", error);
            });
    };

    const handleCardClick = (ListingID) => {
        if (!isLoginHandle()) {
            setKeyData('isLoginModalOpen', true);
            return;
        }

        const existingIds = JSON.parse(localStorage.getItem('listingIds')) || [];

        if (!existingIds.includes(ListingID)) {
            existingIds.push(ListingID);
            setallData({});

            localStorage.setItem('listingIds', JSON.stringify(existingIds));
        }
    };


    return (
        <ContactLayout>
            <Helmet>
                <title>{`${allData?.ListingInfo?.StreetAddress?.Address ? allData?.ListingInfo?.StreetAddress?.Address.split(",")[0] : (allData?.ListingInfo?.StreetAddress?.Address) || 'Property in Miami'} - Luxlife Miami Real Estate LLC`}</title>
            </Helmet>
            {(allData && Object.keys(allData).length !== 0) && <div className="md:max-w-[80%] mx-auto py-4 px-4">
                <ImageViewer intent={allData?.ListingInfo?.MLSDetails?.StandardStatus} images={allData?.Photos ? allData.Photos.map(v => v.MediaURL) : images} />
                <div className='flex justify-between container mx-auto p-4'>
                    <div>
                        <div className='flex'>

                            <h1 className='creato-f-b text-[20px] md:text-[1.3vw]'>{`${allData?.ListingInfo?.StreetAddress?.Address ? allData?.ListingInfo?.StreetAddress?.Address.split(",")[0] : (allData?.ListingInfo?.StreetAddress?.Address)} ${(allData?.ListingInfo?.MLSDetails?.ListingAgreement === 'Exclusive Right To Sell' ? 'For Sale' : allData?.ListingInfo?.MLSDetails?.ListingAgreement) || ""}`}</h1>
                            <button
                                onClick={() => isLoginHandle() ? toggleWishlist(loading, setLoading, isSaved, setIsSaved, allData?.ListingID) : handleCardClick()}
                                disabled={loading}
                                className=" bg-white p-2 rounded-full shadow-md z-10 ml-10"
                            >
                                {isSaved ? (
                                    <AiFillHeart className="text-red-500 text-lg" />
                                ) : (
                                    <AiOutlineHeart className="text-gray-500 text-lg" />
                                )}
                            </button>
                        </div>
                        <div className="flex items-center gap-2  mt-2  ">
                            <img src={bed} alt='' className='w-[1.2vw]' /> <p className='text-[#101010]  text-[15px] lg:text-[0.7vw]  poppins-regular text-nowrap leading-[23px]'>{allData?.PropertyDetails?.Bedrooms} Bed</p>
                            <img src={bath} alt='' className='w-[1.2vw]' /> <p className='text-[#101010]  text-[15px] lg:text-[0.7vw]  poppins-regular text-nowrap leading-[23px]'>{allData?.PropertyDetails?.BathroomsTotal} Bath</p>
                            <img src={area} alt='' className='w-[1.2vw]' /> <p className='text-[#101010]  text-[15px] lg:text-[0.7vw]  poppins-regular text-nowrap leading-[23px]'>{allData?.PropertyDetails?.LivingArea} SQFT</p>
                            <p className='text-[15px] lg:text-[0.7vw]  text-green-500'>{allData?.ListingInfo?.MLSDetails?.StandardStatus}</p>
                        </div>
                        <div className='flex gap-4 lg:gap-16 items-center my-4'> <h2 className='creato-f-b text-[30px] lg:text-[2.7vw] text-nowrap'> ${allData?.ListingInfo?.PriceInfo?.ClosePrice ? allData?.ListingInfo?.PriceInfo?.ClosePrice.toLocaleString() : allData?.ListingInfo?.PriceInfo?.OriginalListPrice ? allData?.ListingInfo?.PriceInfo?.OriginalListPrice?.toLocaleString() : allData?.ListingInfo?.PriceInfo?.ListPrice?.toLocaleString()}</h2> <Button onClick={handleCopyUrl} className={'creato-f-b text-theme_yellow bg-theme_blue py-2 rounded-md'} title={<div className='flex items-center gap-2 '><span>Share</span> <span><IoIosShareAlt /></span></div>} /></div>
                        <div className="flex items-center gap-1  " >
                            <FaLocationDot className='text-[1vw]' /> <p className='text-[#101010] poppins-regular	 text-[15px] lg:text-[0.7vw]  text-nowrap leading-[18px]'>{allData?.ListingInfo?.StreetAddress?.Address}
                            </p>
                        </div>
                    </div>
                    <div>
                        <img src={sec4Logo} alt="" className='my-4' />
                    </div>
                </div>
                <div className='container items-center flex-wrap gap-4 flex md:justify-between mx-auto p-4 border-theme_grey2 border-b-2 pb-[1.5%] my-4'>
                    <div className="flex creato-f-b gap-2 md:gap-10 text-theme_yellow md:text-[30px] lg:text-[1.2vw] "><a href='#overview' className="">Overview</a>
                        <a href='#features' className="text-nowrap">Features & Amenities</a>
                        <a href='#location' className="">Location</a></div>
                    <div className="flex gap-6 items-center">
                        <a href='#agent-card'>
                            <Button className={'border border-theme_blue rounded-full text-theme_yellow creato-f-b bg-white py-3 px-6'} title={'Get in Touch'} />
                        </a>

                        <Button onClick={handleBookAppointment}
                            className={'rounded-full creato-f-b bg-theme_blue py-3 btn2 px-6'} title={'Schedule a Tour'} />

                    </div>
                </div>
                <div className="container flex items-center lg:items-start lg:justify-between flex-col lg:flex-row mx-auto ">
                    <div className="w-full">
                        <div id="overview">
                            <h2 className="text-[35px] lg:text-[45px] xl:text-[3vw] creato-f-b text-theme_yellow">
                                Property Description
                            </h2>
                            <h2 className="text-[25px] lg:text-[2.4vw] creato-f-b mt-2">The Property</h2>
                            <p className="creato-f-b md:text-[.84vw] lg:max-w-[42.5vw] py-6">
                                {allData?.PublicRemarks?.Remarks}
                            </p>
                        </div>

                        <div className="lg:max-w-[42.5vw]">
                            <h2 className="text-[25px] lg:text-[2.36vw] creato-f-b mt-2 mb-8">Overview</h2>

                            <div className="flex md:text-[1.1vw] px-4 py-[3.5%] border-t border-theme_blue">
                                <div className="flex-1"><h2 className="creato-f-b">Property Type</h2></div>
                                <div className="flex-1"><p>{allData?.PropertySubType}</p></div>
                            </div>
                            <div className="flex md:text-[1.1vw] px-4 py-[3.5%] border-t border-theme_blue">
                                <div className="flex-1"><h2 className="creato-f-b">Location</h2></div>
                                <div className="flex-1">
                                    <p>{`${allData?.City || allData?.ListingInfo?.Location?.CountyOrParish}, ${allData?.ListingInfo?.Location?.State}`}</p>
                                </div>
                            </div>
                            <div className="flex md:text-[1.1vw] px-4 py-[3.5%] border-t border-theme_blue">
                                <div className="flex-1"><h2 className="creato-f-b">MLS ID</h2></div>
                                <div className="flex-1"><p>{allData?.ListingInfo?.MLSDetails?.ListingId}</p></div>
                            </div>
                            <div className="flex md:text-[1.1vw] px-4 py-[3.5%] border-t border-theme_blue border-b">
                                <div className="flex-1"><h2 className="creato-f-b">LISTING DATE</h2></div>
                                <div className="flex-1">
                                    <p>{(allData?.ListingInfo?.MLSDetails?.ListingContractDate)}</p>
                                </div>
                            </div>
                        </div>

                        <div className="lg:max-w-[42.5vw] mt-12" id="features">
                            <h2 className="text-[25px] lg:text-[2.36vw] creato-f-b mt-2 mb-8">
                                Property Features & Amenities
                            </h2>

                            <div className="px-4 mt-4">
                                <h3 className="pt-4 text-[20px] lg:text-[1.6vw] text-theme_yellow creato-f-b">
                                    Interior
                                </h3>
                                <div className="flex md:text-[1.1vw] py-4 border-b border-theme_blue">
                                    <div className="flex-1 flex gap-4 items-center">
                                        <BsCircleFill className="text-xs" />
                                        <h2 className="creato-f-b">Total Bedrooms</h2>
                                    </div>
                                    <div className="flex-1">
                                        <p className="creato-f-b">{allData?.PropertyDetails?.Bedrooms}</p>
                                    </div>
                                </div>
                                <div className="flex md:text-[1.1vw] py-4 border-b border-theme_blue">
                                    <div className="flex-1 flex gap-4 items-center">
                                        <BsCircleFill className="text-xs" />
                                        <h2 className="creato-f-b">Total Bathrooms</h2>
                                    </div>
                                    <div className="flex-1">
                                        <p className="creato-f-b">{allData?.PropertyDetails?.BathroomsTotal}</p>
                                    </div>
                                </div>
                                {allData?.PropertyDetails?.LivingArea && <div className="flex md:text-[1.1vw] py-4 border-b border-theme_blue">
                                    <div className="flex-1 flex gap-4 items-center">
                                        <BsCircleFill className="text-xs" />
                                        <h2 className="creato-f-b">Living Area</h2>
                                    </div>
                                    <div className="flex-1">
                                        <p className="creato-f-b">{`${allData?.PropertyDetails?.LivingArea} sq ft`}</p>
                                    </div>
                                </div>}
                                <div className="flex md:text-[1.1vw] py-4 border-b border-theme_blue">
                                    <div className="flex-1 flex gap-4 items-center">
                                        <BsCircleFill className="text-xs" />
                                        <h2 className="creato-f-b">Appliances</h2>
                                    </div>
                                    <div className="flex-1">
                                        <p className="creato-f-b">{allData?.PropertyDetails?.Appliances?.join(", ")}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="px-4 mt-4">
                                <h3 className="pt-4 text-[20px] lg:text-[1.6vw] text-theme_yellow creato-f-b">
                                    Exterior
                                </h3>
                                <div className="flex md:text-[1.1vw] py-4 border-b border-theme_blue">
                                    <div className="flex-1 flex gap-4 items-center">
                                        <BsCircleFill className="text-xs" />
                                        <h2 className="creato-f-b">Year Built</h2>
                                    </div>
                                    <div className="flex-1">
                                        <p className="creato-f-b">{allData?.PropertyDetails?.YearBuilt}</p>
                                    </div>
                                </div>
                                {allData?.PropertyDetails?.RoofType.length > 0 && <div className="flex md:text-[1.1vw] py-4 border-b border-theme_blue">
                                    <div className="flex-1 flex gap-4 items-center">
                                        <BsCircleFill className="text-xs" />
                                        <h2 className="creato-f-b">Roof Type</h2>
                                    </div>
                                    <div className="flex-1">
                                        <p className="creato-f-b">{allData?.PropertyDetails?.RoofType?.join(", ")}</p>
                                    </div>
                                </div>}
                            </div>

                            <div className="px-4 mt-4">
                                <h3 className="pt-4 text-[20px] lg:text-[1.6vw] text-theme_yellow creato-f-b">
                                    Details
                                </h3>
                                <div className="flex md:text-[1.1vw] py-4 border-b border-theme_blue">
                                    <div className="flex-1 flex gap-4 items-center">
                                        <BsCircleFill className="text-xs" />
                                        <h2 className="creato-f-b">Subdivision</h2>
                                    </div>
                                    <div className="flex-1">
                                        <p className="creato-f-b">{allData?.ListingInfo?.Location?.Subdivision}</p>
                                    </div>
                                </div>
                                <div className="flex md:text-[1.1vw] py-4 border-b border-theme_blue">
                                    <div className="flex-1 flex gap-4 items-center">
                                        <BsCircleFill className="text-xs" />
                                        <h2 className="creato-f-b">Address</h2>
                                    </div>
                                    <div className="flex-1">
                                        <p className="creato-f-b">
                                            {`${allData?.ListingInfo?.StreetAddress?.Address}`}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="px-4 mt-4">
                                <h3 className="pt-4 text-[20px] lg:text-[1.6vw] text-theme_yellow creato-f-b">
                                    Financial
                                </h3>
                                {allData?.ListingInfo?.PriceInfo?.OriginalListPrice && <div className="flex border-b border-theme_blue md:text-[1.1vw] py-4">
                                    <div className="flex-1 flex gap-4 items-center">
                                        <BsCircleFill className="text-xs" />
                                        <h2 className="creato-f-b">Original List Price</h2>
                                    </div>
                                    <div className="flex-1">
                                        <p className="creato-f-b">${(allData?.ListingInfo?.PriceInfo?.OriginalListPrice?.toLocaleString())}</p>
                                    </div>
                                </div>}
                                {allData?.ListingInfo?.PriceInfo?.ListPrice && <div className="flex border-b border-theme_blue md:text-[1.1vw] py-4">
                                    <div className="flex-1 flex gap-4 items-center">
                                        <BsCircleFill className="text-xs" />
                                        <h2 className="creato-f-b">List Price</h2>
                                    </div>
                                    <div className="flex-1">
                                        <p className="creato-f-b">${(allData?.ListingInfo?.PriceInfo?.ListPrice?.toLocaleString())}</p>
                                    </div>
                                </div>}
                                {allData?.ListingInfo?.PriceInfo?.ClosePrice && <div className="flex border-b border-theme_blue md:text-[1.1vw] py-4">
                                    <div className="flex-1 flex gap-4 items-center">
                                        <BsCircleFill className="text-xs" />
                                        <h2 className="creato-f-b">Close Price</h2>
                                    </div>
                                    <div className="flex-1">
                                        <p className="creato-f-b">${(allData?.ListingInfo?.PriceInfo?.ClosePrice?.toLocaleString())}</p>
                                    </div>
                                </div>}
                                <div className="flex md:text-[1.1vw] py-4 ">
                                    <div className="flex-1 flex gap-4  items-center">
                                        <BsCircleFill className="text-xs" />
                                        <h2 className="creato-f-b">Days on Market</h2>
                                    </div>
                                    <div className="flex-1">
                                        <p className="creato-f-b">{allData?.ListingInfo?.PriceInfo?.DaysOnMarket}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {showPopup && (
                        <PopupModal
                            url="https://calendly.com/luxlifemiamirealestate/30min"
                            prefill={{
                                name: (data?.isLogin?.first_name + ' ' + data?.isLogin?.last_name) || '',
                                email: data?.isLogin?.email || '',
                                customAnswers: {
                                    a1: `Property Link: ${window.location.href} \n Property Key: ${allData?.ListingID} \n MLS Property ID: ${allData?.ListingInfo.MLSDetails.ListingId}`,
                                },
                            }}
                            pageSettings={{
                                backgroundColor: 'ffffff',
                                textColor: '000000',
                                hideEventTypeDetails: false,
                            }}
                            onModalClose={() => setShowPopup(false)}
                            rootElement={document.getElementById('root')}
                            open={true}
                        />
                    )}

                    <div className={`pt-0 px-4  relative`} id="agent-card">
                        <div className={`${isStuck ? ' sticky top-0 right-0 ' : ''} `}>
                            {/* <DatePicker allData={allData} /> */}
                            <div className='mt-24'>
                                <AgentCard />
                            </div>
                        </div>

                    </div>
                </div>
                <div id="location" className='w-full my-8'>
                    <div className='container mx-auto '>
                        <h2 className=' text-[25px] lg:text-[2.36vw] creato-f-b mt-2 mb-8'>Location</h2>
                    </div>

                    <div className="w-full h-[400px]">
                        <MapComponent coords={allData?.ListingInfo?.Location} nameLocation={allData?.ListingInfo?.StreetAddress?.PostalCode + ' ' + allData?.ListingInfo?.Location?.CountyOrParish} />
                    </div>
                </div>

                {Array.isArray(properties) && properties.length > 0 && <div className='container py-20 mx-auto creato-f-b text-theme_blue'>
                    <h2 className=' text-[25px] lg:text-[2.36vw] mt-2 '>Discover More!</h2>
                    <p className='max-w-[1000px] lg:text-[1.1vw]'>
                        Discover our expertly curated collection of properties for sale. With a focus on quality and value, we offer
                        some of the best properties on the market.
                    </p>

                    <div className='flex justify-center flex-wrap gap-x-8 mt-10'>
                        {/* {[properties.slice(0, 3)].map(v => <AnimationDiv
                            key={v.name}
                            className="lg:w-[20vw]"
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.2 }}
                            variants={cardVariants}
                        > <Card data={v} key={v.name} className={`lg:w-[20vw] shadow-lg`} /> </AnimationDiv>)} */}

                        {Array.isArray(properties) && properties.slice(0, 3).map(v => <AnimationDiv
                            key={v.name}
                            className="lg:w-[20vw]"
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.2 }}
                            variants={cardVariants}
                        > <Card data={v} key={v.name} className={`lg:w-[20vw] shadow-lg`} property_url={true} toggleWishlist={toggleWishlist} /> </AnimationDiv>)}
                    </div>

                    <div className='flex justify-center mt-14'>
                        <Button onClick={() => navigate('/miami/on-filter', { state: { filter: `PropertySubType.in=${allData?.PropertySubType}&UnparsedAddress=${allData?.City}` } })} title={<div className='flex items-center gap-4'><span>View more</span> <span><IoIosArrowForward className='text-[20px]' /></span></div>} className={'bg-theme_blue px-8 text-theme_yellow rounded-full py-3 creato-f-m  tracking-wide btn2'} />
                    </div>
                </div>}
            </div>}
            {!(allData && Object.keys(allData).length !== 0) &&
                <div className="h-[100vh] inset-0 bg-white bg-opacity-20 flex items-center justify-center z-50">
                    <Spin size="large" spinning={true} indicator={<LoadingOutlined spin />} />
                </div>}

        </ContactLayout>
    )
}

export default SingleProperty