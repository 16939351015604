import React from 'react'
import Footer from '../components/ui/sections/FooterSection/Footer'
import Header from '../components/ui/header/Header'
import ContactUs from '../components/ui/sections/ContactUsSection/ContactUs'
import { Helmet } from 'react-helmet'
import lux404 from '../assets/images/lux404.jpeg'
import { Link } from 'react-router-dom'
import Button from '../components/buttons/Button'
import { FiChevronRight } from 'react-icons/fi'
const Page404 = () => {
    return (
        <>
         <Helmet>
                    <title>404 - Luxlife Miami Real Estate LLC</title>
                </Helmet>
            <div className=' bg-theme_blue'>
                <Header />
            </div>
            <div className='flex flex-col sm:md:lg:xl:flex-row align-center'>
<img src={lux404} alt='lux404' style={{maxHeight:"100vh"}} />
<div className='flex flex-col justify-center'>
    <p className='text-[1.5rem] sm:md:lg:text-[3rem] mx-3 text-[#00355F] creato-f-b'>Sorry, That Page Was Not Found,</p>
    <p className='text-[1.5rem] sm:md:lg:text-[3rem] mx-3 text-[#00355F] creato-f-b'>But don’t worry, there are plenty</p>
    <p className='text-[1.5rem] sm:md:lg:text-[3rem] mx-3 text-[#00355F] creato-f-b'>of other great ones to check out!</p>

    <Link to='/' className='text-center sm:md:lg:text-start mb-10'>

<Button
                             title={<div className="flex items-center justify-center gap-2 text-white   ">
                              Return to Home
                              <FiChevronRight size={30} />
                          </div>}
                                className={'mt-8 text-[20px] rounded-[22px] px-8 creato-f-r'}
                                />
                          </Link>
</div>
            </div>
            <div className='flex flex-col-reverse'>
                <ContactUs className={'mt-0'} />
            </div>
            <Footer className={'mt-0'} />
        </>
    )
}

export default Page404