import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import ReactGA from "react-ga4";

import Home from './pages/Home';
import InvestmentMiami from './pages/investment-miami';
import Contact from './pages/Contact';
import Subscribe from './pages/Subscribe';
import MapPage from './pages/MapPage';
import New100Listing from './pages/New100Listing';
import { ConfigProvider, Modal, Spin } from 'antd';
import HomesPage from './pages/HomesPage';
import Condos from './pages/Condos';
import SellProperties from './pages/SellProperties';
import About from './pages/About';
import SingleProperty from './pages/SingleProperty';
import ScrollToTop from './components/Scroll/ScrollTop';
import PreConstruct from './pages/PreConstruct';
import SinglePreContruction from './pages/SinglePreConstruction';
import { LoginSignUp } from './components/auth/LoginSignup';
import Blog from './pages/Blog';
import SingleBlog from './pages/SingleBlog';
import { DataProvider, useData } from './context/DataContext';
import { useContext, useEffect, useState } from 'react';
import { getDefaultSearchData, getPropertyTypes } from './api/GET';
import { ToastContainer } from 'react-toastify';
import SavedProperties from './pages/SavedProperties';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import MyProfile from './pages/Profile';
import CustomSingleProperty from './pages/CustomSingleProperty';
import Page404 from './pages/404';
import SingleBuilding from './pages/SingleBuilding';

function App() {
  const { setKeyData, data } = useData()

  const checkLogin = () => {
    let loginData = localStorage.getItem('user_data')
    try {
      if (loginData) {
        loginData = JSON.parse(loginData)
        setKeyData('isLogin', loginData)
      }

    } catch (error) {

    }
  }

  const fetchSearchListing = async () => {
    const data = await getDefaultSearchData();
    if (Array.isArray(data?.data)) {
      setKeyData(
        'autoSearchList',
        data.data
      )
    }
  }

  useEffect(() => {

    const fetchPropertyType = async () => {
      try {
        const data = await getPropertyTypes();
        if (Array.isArray(data?.data)) {
          setKeyData(
            'propertyType',
            data.data.map(v => ({
              label: v,
              link: `/new-100/${v.replaceAll(" ", "-").replaceAll('/', '-')}`
            }))
          );
        } else {
          console.error('Invalid data format received:', data);
        }
      } catch (error) {
        console.error('Error fetching property types:', error);
        // Optionally, you can set an error state or show an alert
        // setError('Failed to load property types.');
      }
    };

    ReactGA.initialize("G-4ZKY5FWW4Y");

    fetchPropertyType();
    checkLogin();
    fetchSearchListing()

  }, []);


  const handleCancel = () => {
    setKeyData('isLoginModalOpen', false);
  };
  const handleSetLoginModalTitle = (value) => {
    setKeyData('loginModalTitle', value);

  }



  return (
    <ConfigProvider
      theme={{
        components: {
          Slider: {
            handleColor: '#E4A231'
            , handleActiveOutlineColor: '#E4A231',
            handleLineWidth: 5

          },
          Dropdown: {
            zIndexPopup: 100000

          },
          Input: {
            colorBgContainer: '#fff', // Input background color
            colorBorder: '#d1d5db', // Input border color
            colorText: '#E4A231',
          },
          Image: {
            zIndexPopup: 999999999999 /* here is your component tokens */
          },
        },
        token: {
          // Seed Token
          colorPrimary: '#E4A231',
          colorBorder: '#E4A231',
          // Alias Token
          colorBgContainer: '#f7f5f5',

        },
      }}
    >
 

 <BrowserRouter>
        <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar={false} />
        <ScrollToTop />
        <Modal maskClosable={false} className='rounded-[30px] p- overflow-hidden' zIndex={99999999} centered footer={false} open={data?.isLoginModalOpen} onOk={handleCancel} onCancel={handleCancel}>
          <LoginSignUp title={data.loginModalTitle} setTitle={handleSetLoginModalTitle} handleClose={handleCancel} />
        </Modal>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/homes' element={<HomesPage />} />
          <Route path='/about' element={<About />} />
          <Route path='/Condos' element={<Condos />} />
          <Route path='/map' element={<MapPage />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/profile' element={<MyProfile />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/terms-of-service' element={<TermsOfService />} />
          <Route path='/blog/:id' element={<SingleBlog />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/saved-properties' element={<SavedProperties />} />
          <Route path='/subscribe' element={<Subscribe />} />
          <Route path='/sell-properties' element={<SellProperties />} />
          <Route path='/pre-construction' element={<PreConstruct />} />
          <Route path='/pre-construction/:id' element={<SinglePreContruction />} />
          <Route path='/building/:id' element={<SingleBuilding />} />
          <Route path='/single-property/:id' element={<SingleProperty />} />
          <Route path='/new-100/:tab' element={<New100Listing />} />
          <Route path='/custom-single-property/:id' element={<CustomSingleProperty />} />

          <Route path='/miami/:slug' element={<InvestmentMiami />} />
          <Route path='*' element={<Page404 />} />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
