


import React, { useState, useEffect } from 'react';
import { GoogleMap, useJsApiLoader, Marker, Polygon, InfoWindow } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';
import { cityPolygons } from '../../lib/utils';

const mapContainerStyle = {
    width: '100%',
    height: '100%'
};

const MapComponent = ({ coords, nameLocation, data, selectedCityNames }) => {
    console.log(coords);
    const navigate = useNavigate();
    const [selectedMarker, setSelectedMarker] = useState(null);
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyDIhdl8K0VsF3UJ7nYsT5ttYyU22konJ9c', // Make sure to set this in your .env file
        libraries: ['geometry', 'drawing']
    });

    // Default position (Miami)
    const defaultCenter = coords
        ? { lat: Number(coords.Latitude), lng: Number(coords.Longitude) }
        : { lat: 25.7609329, lng: -80.14004589999999 };

    const handleMarkerClick = (property) => {
        navigate(`/single-property/${property.ListingID}`);
    };

    const matchingPolygons = selectedCityNames && selectedCityNames
        .filter(city => cityPolygons[city])
        .map(city => {
            const coordinatesArray = cityPolygons[city];
            let polygonCoordinates = [

            ];
            if (coordinatesArray) {
                polygonCoordinates = coordinatesArray.map(v => ({ lat: v[1], lng: v[0] }))
            }
            return { city, polygonCoordinates };
        });

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    return (
        <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={defaultCenter}
            zoom={10}
        >
            {/* Render markers */}
            {coords && (
    <Marker
        key={`coords-marker-${coords.Latitude}-${coords.Longitude}`} // Ensure unique key
        position={{
            lat: Number(coords.Latitude),
            lng: Number(coords.Longitude)
        }}
        title={`${coords.Subdivision}, ${coords.CountyOrParish}`} // Optional tooltip
    />
)}
            {data?.map(property =>
                property?.ListingInfo?.Location?.Latitude ? (
                    <Marker
                        key={property.ListingID}
                        position={{
                            lat: property.ListingInfo.Location.Latitude,
                            lng: property.ListingInfo.Location.Longitude
                        }}
                        onClick={() => {
                            setSelectedMarker(property);
                            handleMarkerClick(property);
                        }}
                    >
                        {selectedMarker === property && (
                            <InfoWindow
                                onCloseClick={() => setSelectedMarker(null)}
                            >
                                <div>
                                    {property?.ListingInfo?.StreetAddress?.PostalCode}{' '}
                                    {property?.ListingInfo?.Location?.CountyOrParish}
                                </div>
                            </InfoWindow>
                        )}
                    </Marker>
                ) : null
            )}

            {/* Render city polygons */}
            {matchingPolygons?.map(({ city, polygonCoordinates }) => (
                <Polygon
                    key={city}
                    paths={polygonCoordinates}
                    options={{
                        fillColor: "#E4A231",
                        fillOpacity: 0.35,
                        strokeColor: "#E4A231",
                        strokeOpacity: 0.8,
                        strokeWeight: 2
                    }}
                />
            ))}
        </GoogleMap>
    );
};

export default MapComponent;