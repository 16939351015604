import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const FooterDirectory = () => {

    const [buidings, setBuildings] = useState([]);



    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("https://www.luxlifemiamirealestate.com/api/api/import-buildings/getnames");
                if (response.ok) {
                    const data = await response.json();
                    if (data.data && data.data.length > 0) {

                        setBuildings(data.data);
                    }
                } else {
                    console.error("Error fetching data");
                }
            } catch (error) {
                console.error("Fetch error:", error);
            }
        };
        fetchData();
    }, []);

      const categorizedBuildings = buidings.reduce((acc, building) => {
        const location = building.building ?? 'Uncategorized Building';
        if (!acc[location]) {
          acc[location] = [];
        }
        acc[location].push(building);
        return acc;
      }, {});



 
  
    return (
        <div className="flex justify-center bg-[#f2f0f0] bottom-[-95px]" style={{position:"relative"}}>
            <div className="container lg:max-w-[75%]">
                <p className="creato-f-b text-[2rem]  mt-20" style={{ textAlign: "center" }}>The Luxlife Directory of Miami and South Florida Condos, Homes and Preconstruction</p>
                <div className="pt-12 columns-1 sm:columns-2 md:columns-3 lg:columns-5 gap-6 mb-10 px-4 md:px-8 lg:px-12">
  {Object.entries(categorizedBuildings)
    .sort(([locationA], [locationB]) => locationA.localeCompare(locationB))
    .map(([location, buildings]) => (
      <div key={location} className="mb-4">
        <p className="text-base creato-f-r mt-1 pb-1 font-bold">{location}</p>
        {buildings.map((building) => (
          <Link
            key={building.name}
            className="text-sm creato-f-l block hover:text-theme_yellow mt-1"
            to={`/building/${building.name.toLowerCase().replace(/\s+/g, "_")}`}
          >
            {building.name}
          </Link>
        ))}
      </div>
    ))}
</div>


            </div>
        </div>
    )
}


export default FooterDirectory