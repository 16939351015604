import React, { useState, useMemo, useEffect } from 'react';
import { motion } from 'framer-motion';
import { IoSearch } from 'react-icons/io5';
import { getAllCities } from '../../../api/GET';
import { useNavigate } from 'react-router-dom';
import { useData } from '../../../context/DataContext';
import { cn } from '../../../lib/utils';

const AutoCompleteSearch = ({ style }) => {
    const [searchValue, setSearchValue] = useState('');
    const [citiesList, setCitiesList] = useState([]);
    const [searchType, setSearchType] = useState('City');
    const [selectedSearch, setSelectedSearch] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const navigate = useNavigate();
    const { data: { autoSearchList } } = useData();

    useEffect(() => {
        const fetchCities = async () => {
            const data = await getAllCities();
            if (Array.isArray(data?.data?.uniqueCities)) {
                setCitiesList(
                    data.data.uniqueCities.map(v => ({
                        label: v,
                        value: v
                    }))
                );
            }
        };
        fetchCities();
    }, []);

    const handleFilterOption = (searchValue) => {
        if (!searchValue) return [];

        switch (searchType) {
            case 'Property':
                return autoSearchList
                    .filter(item =>
                        item.UnparsedAddress.toLowerCase().includes(searchValue.toLowerCase())
                    )
                    .map(d => ({
                        value: d.UnparsedAddress,
                        label: d.UnparsedAddress,
                        ListingKey: d.ListingKey,
                    }));
                    
                    case 'City':
                        return citiesList.filter(city =>
                            city.label.toLowerCase().includes(searchValue.toLowerCase())
                        );
                        
            case 'MLS':
                return autoSearchList
                    .filter(item =>
                        item.ListingId?.toLowerCase().includes(searchValue.toLowerCase())
                    )
                    .map(d => ({
                        value: d.ListingId,
                        label: d.ListingId,
                        ListingKey: d.ListingKey,
                    }));

            default:
                return [];
        }
    };

    const filteredOptions = useMemo(() => handleFilterOption(searchValue), [searchValue, searchType, autoSearchList, citiesList]);

    const handleSearchFilter = (e) => {
        const value = e.target.value;
        setSearchValue(value);
        setSelectedSearch(null);
        setIsDropdownOpen(value.length > 0);
    };

    const handleSelectOption = (selectedValue) => {
        setSearchValue(selectedValue?.value);
        setSelectedSearch(selectedValue?.value);
        console.log('selectedValue', selectedValue);
        console.log('selectedValues', searchType);
        
        setIsDropdownOpen(false);
    };

    const handleSearchTypeChange = (e) => {
        setSearchType(e.target.value);
        setSearchValue('');
        setSelectedSearch(null);
    };

    const handleSearch = () => {
        let searchQuery = '';

        if (!searchValue) return;

        const searchItem = (handleFilterOption(searchValue).length > 0 ? handleFilterOption(searchValue)[0].value : searchValue) || searchValue;
        switch (searchType) {
            case 'City':
                searchQuery = searchItem ? `UnparsedAddress.in=${searchItem}` : '';
                break;
            case 'Property':
                searchQuery = searchItem ? `UnparsedAddress.in=${searchItem}` : '';
                break;
            case 'MLS':
                searchQuery = searchItem ? `ListingId.in=${searchItem}` : '';
                break;
        }
        if (searchQuery.trim()) {
            navigate('/miami/on-filter', { state: { filter: searchQuery } });
        }
    };

    return (
        <div className='flex w-full items-center justify-between relative'>
            <div className='relative flex-1 max-w-[300px]'>
                <input
                    type="text"
                    value={searchValue}
                    onChange={handleSearchFilter}
                    onFocus={() => searchValue && setIsDropdownOpen(true)}
                    placeholder={`Search Luxlife by ${searchType}`}
                    className={cn('w-full px-3 py-2  rounded-md focus:outline-none  border-none tracking-wider font-bold', style?.search)}
                />
                {isDropdownOpen && filteredOptions.length > 0 && (
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        className='absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg max-h-60 overflow-y-auto'
                    >
                        {filteredOptions.map((option) => (
                            <div
                                key={option.value}
                                onClick={() => handleSelectOption(option)}
                                className='px-3 py-2 hover:bg-yellow-100 cursor-pointer font-semibold'
                            >
                                {option.label}
                            </div>
                        ))}
                    </motion.div>
                )}
            </div>

            <select
                value={searchType}
                onChange={handleSearchTypeChange}
                className={cn('ml-2 px-2 py-2 border rounded-md text-yellow-600 focus:outline-none  border-none focus:border-none tracking-wider ', style?.type)}
            >
                <option value="Property">Property</option>
                <option value="City">City</option>
                <option value="MLS">MLS ID</option>
            </select>

            <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className='ml-2 text-yellow-600 cursor-pointer'
                onClick={handleSearch}
            >
                <IoSearch className={cn('text-[40px]', style?.btn)} />
            </motion.div>
        </div>
    );
};

export default AutoCompleteSearch;