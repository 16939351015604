import React, { useCallback, useEffect, useRef, useState } from 'react';
import Header from '../components/ui/header/Header';
import Map from '../components/map/Map';
import Filter from '../components/ui/filter/Filter';
import Card from '../components/cards/Card';
import SortDropdown from '../components/forms/dropdown/SortDropdown';
import Footer from '../components/ui/sections/FooterSection/Footer';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
import axios from 'axios';
import { getListingData } from '../api/GET';
import { API_BASE_URL } from '../config';
import miamiLogo from '../assets/images/miami-logo.png';
import sec3logo from '../assets/images/sec4_logo.svg';
import { makeQueryFromObj } from '../lib/helper';
import { Helmet } from 'react-helmet';



const DEFAULT_CITIES = [
    "Aventura",
    "Brickell",
        "Bal Harbour",
        "Bay Harbor Islands",
        "Bay Harbour",
        "Boca Raton",
        "Coconut Grove",
        "Coral Gables",
        "Cutler Bay",
        "Doral",
        "Edgewater",
        "Fisher Island",
        "Fort Lauderdale",
        "Golden Beach",
        "Hallandale Beach",
        "Hibiscus Dr",
        "Hollywood",
        "Indian Creek Dr",
        "Pine Tree Dr",
        "Key Biscayne",
        "La Gorce Dr",
        "Manalapan",
        "Miami",
        "Miami Beach",
        "Miami Gardens",
        "Miami Lakes",
        "Miami Shores",
        "North Bay Village",
        "North Miami",
        "North Miami Beach",
        "Palm Beach County",
        "Pinecrest",
        "Pompano Beach",
        "South Miami",
        "Sunny Isles Beach",
        "Star Island",
        "Surfside",
        "Venetian Dr",
        "West Palm Beach",
        "Weston"
];

const ensureFilterQuery = (query) => {
    const queryParams = new URLSearchParams(query);

    // Add `OriginalListPrice.gte` if it doesn't exist
    if (!queryParams.has("OriginalListPrice.gte")) {
        queryParams.append("OriginalListPrice.gte", "1000000");
    }

    // Add default `City` values if none exist
    if (!queryParams.has("UnparsedAddress")) {
        DEFAULT_CITIES.forEach((city) => {
            queryParams.append("UnparsedAddress.in", city.replace(/\s+/g, "+"));
        });
    }

    return queryParams.toString();
};

const InvestmentMiami = () => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [properties, setProperties] = useState([]);
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState(null);
    const [filterQuery, setFilterQuery] = useState(() => {
        const initialFilter = location.state?.homeFilter
            ? makeQueryFromObj(location.state?.homeFilter) : location.state?.cardFilter ? location.state?.cardFilter
                : 'OriginalListPrice.gte=1000000';
        return ensureFilterQuery(initialFilter);
    });
    const [sortedData, setSortedData] = useState('sortBy=OnMarketDate&order=desc');
    const [listingData, setListingData] = useState([]);
    const [loadMore, setLoadMore] = useState(false);
    const containerRef = useRef(null);
    const footerContainerRef = useRef(null);

    const routes = {
        'investment-miami': 'Investment properties in',
        'luxury-penthouses': 'Luxury Penthouses for Sale in',
        'family-homes-sale': 'Family Homes for Sale in',
        'luxury-appartment-sale': 'Luxury Apartments for Sale in',
        'on-filter': "Here's what we found based on your applied Filter",
    };
    console.log('filterQuery', filterQuery)
    useEffect(() => {
        // Ensure the filter query is only updated once on load
        if (location.state?.homeFilter) {
            const initialQuery = makeQueryFromObj(location.state?.homeFilter);
            const updatedQuery = ensureFilterQuery(initialQuery);
            setFilterQuery(updatedQuery);
        } else if (location.state?.cardFilter) {
            setFilterQuery(location.state?.cardFilter)
        } else {
            // Apply default query only once
            const updatedQuery = ensureFilterQuery('OriginalListPrice.gte=1000000');
            setFilterQuery(updatedQuery);
        }
    }, [location.state?.homeFilter]);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      // Simulate a delay for demonstration purposes
      const loadSite = async () => {
        await new Promise((resolve) => setTimeout(resolve, 2000)); // Simulate data fetching
        setIsLoading(false);
      };
  
      loadSite();
    }, []);

    useEffect(() => {
        if (!routes[slug]) {
            navigate('/');
        }
    }, [slug, routes, navigate]);
    let a = 0
    useEffect(() => {
        const fetchDataWithFallback = async (queries) => {
            for (const query of queries) {
                const data = await getListingData(query, true);
                if (Array.isArray(data?.data?.data) && data.data.data.length > 0) {
                    if (data?.data) {
                        setListingData(data.data);
                    }
                    return data.data.data;
                }
            }
            return [];
        };

        const extractQueryFromFilter = () => {
            if (slug === 'on-filter' && location.state?.filter) {


                return [location.state.filter];
            }
            return [];
        };

        const fetchProperties = async () => {
            setLoading(true);

            const query = {
                offset: 0,
                limit: 15,
            };
            const searchParams = new URLSearchParams(query).toString();
            const additionalFilters = extractQueryFromFilter();
            if (additionalFilters.length > 0) {
                const queriesToTry = additionalFilters.map((filter) =>
                    `${filter ? `${filter}&OriginalListPrice.gte=1000000` : ''}`
                );
                const data = await fetchDataWithFallback(queriesToTry);
                setProperties(data);
            } else {
                const data = await getListingData(
                    `${searchParams ? `&${searchParams}` : ''}${filterQuery ? `&${filterQuery}` : ''}${sortedData ? `&${sortedData}` : ''}`
                    , location.state?.cardFilter);
                if (Array.isArray(data?.data?.data)) {
                    setProperties(data?.data?.data);

                    if (data?.data) {

                        setListingData(data?.data);
                    }
                }
            }

            setLoading(false);
        };

        fetchProperties();

    }, [filterQuery, sortedData, location.state, slug]);

    const extractUnparsedAddress = (query) => {
        const regex = /UnparsedAddress\.in=([^&]*)/;
        const match = query.match(regex);
        return match ? decodeURIComponent(match[1]).replace(/\+/g, ' ') : null;
    };

    useEffect(() => {
    
        const setNameFromQuery = () => {
            const query = `${filterQuery}${location.state?.cardFilter ? `&${location.state.cardFilter}` : ''}`;
            const addressName = extractUnparsedAddress(query);
            console.log('addressName', addressName);
            
            setName(addressName);
        };
    
        setNameFromQuery();
    }, [filterQuery, sortedData, location.state, slug]);

    

    const onScrollEnd = async () => {
        setLoadMore(true);
        const currentData = { ...listingData };

        if (currentData?.has_more) {
            const query = {
                offset: currentData.next_offset,
                limit: 15,
            };
            const searchParams = new URLSearchParams(query).toString();
            
            const data = await getListingData(slug === 'on-filter' && location.state?.filter ? (`&${searchParams}&${location.state?.filter}&OriginalListPrice.gte=1000000`) : (`&${searchParams}&${filterQuery}${sortedData ? `&${sortedData}` : '&'}`), location.state?.cardFilter);
            
            if (Array.isArray(data?.data?.data)) {
                setProperties((prev) => [...prev, ...data?.data?.data]);
            }

            if (data?.data) {
                const newData = {
                    ...data.data,
                    data: [...listingData.data, ...data.data?.data],
                };
                setListingData(newData);
            }
        }
        setLoadMore(false);
    };
    const handleScroll = useCallback(
        debounce((event) => {
            const container = event.target;
            const isAtBottom =
                container.scrollHeight - container.scrollTop <= container.clientHeight + footerContainerRef.current?.clientHeight + 50;
            if (isAtBottom) {
                onScrollEnd?.();
            }
        }, 200),
        [onScrollEnd]
    );

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);

            return () => {
                handleScroll.cancel();
                container.removeEventListener('scroll', handleScroll);
            };
        }
    }, [handleScroll]);

    const toggleWishlist = async (loading, setLoading, isSaved, setIsSaved, ListingID) => {
        if (loading) return;

        setLoading(true);

        const token = JSON.parse(localStorage.getItem('user_data'))?.token;
        const headers = {
            Authorization: `Bearer ${token}`,
        };

        try {
            if (!isSaved) {
                setIsSaved(true);
                await axios.post(
                    `${API_BASE_URL}domainparmeter/saved-property/store`,
                    { listing_id: ListingID },
                    { headers }
                );
            } else {
                setIsSaved(false);
                await axios.delete(
                    `${API_BASE_URL}domainparmeter/saved-property/${ListingID}`,
                    { headers }
                );
            }
        } catch (error) {
            console.error('Error toggling wishlist:', error);
        } finally {
            setLoading(false);
        }
    };



    return (
        routes[slug] && (
            <div>
                <Helmet>
                    <title>{`${ routes[slug]} ${(slug === 'investment-miami') ? ((name) ?? 'Miami') : (slug === 'luxury-penthouses') ? ((name) ?? 'Miami') : (slug === 'family-homes-sale') ? ((name) ?? 'Miami') : (slug === 'luxury-appartment-sale') ? ((name) ?? 'Miami') : ''} - Luxlife Miami Real Estate LLC`}</title>
                </Helmet>
                {isLoading && (
  <div className="fixed inset-0 bg-white bg-opacity-20 flex items-center justify-center z-50">
    <Spin
      size="large"
      spinning={isLoading}
      indicator={<LoadingOutlined spin />}
    />
  </div>
)}
                <div className=' bg-theme_blue'>
                    <Header />
                </div>
                <div className='p-4  py-[20px] lg:pl-[70px] lg:pr-[48px] bg-theme_yellow flex gap-10 justify-center'>
                    <Filter setfitlerQuery={(newQuery) => {
                        const updatedQuery = ensureFilterQuery(newQuery);                        
                        setName(extractUnparsedAddress(updatedQuery))
                        setFilterQuery(updatedQuery);
                    }} defaultfilterValue={location.state?.homeFilter} />
                </div>
                <div className='flex flex-col lg:flex-row  justify-between '>
                    <div ref={containerRef} className='px-2 md:pl-[2%] pt-[45px] md:pr-[2%] flex-1 lg:w-[55vw]  overflow-y-auto lg:h-[calc(100vh-230px)] px-[2%] '>

                        <h1 className="creato-f-l text-[30px]">{routes[slug]} {(slug === 'investment-miami') ? ((name) ?? 'Miami') : (slug === 'luxury-penthouses') ? ((name) ?? 'Miami') : (slug === 'family-homes-sale') ? ((name) ?? 'Miami') : (slug === 'luxury-appartment-sale') ? ((name) ?? 'Miami') : ''}</h1>
                        <div className='flex justify-between mt-[23px] text-gray-400'>
                            <p className='flex items-center'><span><SortDropdown setSortedData={setSortedData} /></span></p>
                            <p><span>{listingData?.total ?? 0} result</span></p>

                        </div>
                        <Spin spinning={loading} indicator={<LoadingOutlined spin />}>
                            <div className="flex flex-wrap gap-4 justify-center mt-[35px]">
                                {properties.map((data) => (
                                    <Card
                                        key={data.id}
                                        data={data}
                                        toggleWishlist={toggleWishlist}
                                        className="lg:w-[20vw] shadow-xl min-w-[300px]"
                                    />
                                ))}
                            </div>
                        </Spin>
                        {loadMore && <div className='flex items-center justify-center mt-4'>
                            <Spin indicator={<LoadingOutlined spin />} /></div>}
                        <div className='py-4' ref={footerContainerRef}  >
                            <div className='max-w-[1300px] mx-auto text-center my-14 text-theme_grey2 text-[18px] leading-[24px] tracking-wider px-8'>

                                The data relating to real estate for sale on this website comes in part from the Miami Association of Realtors, Realtor Association of Greater Ft. Lauderdale, and the South Broward Board of Realtors. Information is deemed reliable but not guaranteed. Copyright © 2024, Miami Association of Realtors, Realtor Association of Greater Ft. Lauderdale, and the South Broward Board of Realtors. All rights reserved. The information being provided is for consumers' personal, non-commercial use and may not be used for any purpose other than to identify prospective properties consumers may be interested in purchasing. The use of search facilities of data on the site, other than a consumer looking to purchase real estate, is prohibited.
                            </div>
                            <div className='flex justify-center gap-6 md:gap-12 flex-wrap items-center' >
                                <img alt='' src={miamiLogo} className='max-w-[300px]' />
                                <img alt='' src={sec3logo} className='max-w-[300px]' />
                            </div>
                        </div>

                    </div>
                    <aside className="lg:w-[45vw] lg:h-[calc(100vh-230px)] relative">
                        <Map data={properties} />
                    </aside>
                </div>
                {/* <Footer /> */}
            </div>
        )
    );
};

export default InvestmentMiami;
