import React, { useState, useEffect } from "react";
import logo from '../../../assets/images/logo.svg';
import sec3logo from '../../../assets/images/sec4_logo.svg';
import { IoClose, IoSearch } from "react-icons/io5";
import { AnimatePresence, motion } from 'framer-motion';
import { GiHamburgerMenu } from "react-icons/gi";
import Button from "../../buttons/Button";
import { ImCross } from "react-icons/im";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import AuthModal from "../../auth/AuthModal";
import { useData } from "../../../context/DataContext";
import LoggedInUser from "../../auth/LoggedInUser";
import AutoCompleteSearch from "../../forms/select/AutoCompleteSearch";
import { API_BASE_URL } from "../../../config";
import axios from "axios";
import { FiChevronRight } from "react-icons/fi";
import agent from '../../../assets/images/agent-card.svg'
import { WhatsAppOutlined, MailOutlined } from '@ant-design/icons';
import { CgProfile } from "react-icons/cg";
import { IoMdHeart } from "react-icons/io";
import { CiLogin } from "react-icons/ci";

const DropdownMenu = ({ items, isScrolled }) => {
    return (
        <ul className={`absolute top-full left-0 bg-white shadow-md rounded-md z-[10000000000000000] overflow-hidden ${isScrolled ? 'text-theme_yellow' : 'text-black'}`}>
            {items.map((item, index) => (
                <li
                    key={index}
                    className="relative border-b before:content-[''] before:absolute before:left-0 before:top-0 before:bottom-0 before:w-[10px] before:hover:w-[18px] before:bg-theme_yellow before:transition-width before:duration-300"
                >
                    <Link
                        to={item.link}
                        className={`block pl-6 px-4 py-2 hover:text-theme_yellow poppins-regular text-sm ${isScrolled ? '' : 'hover:text-theme_yellow'
                            }`}
                    >
                        {item.label}
                    </Link>
                </li>
            ))}
        </ul>
    );
};

const top100Items = [
    { label: '100 Newest Listings', link: '/new-100/listing' },
    { label: '100 Newest Penthouses', link: '/new-100/penthouses' },
    { label: '100 Newest Condos', link: '/new-100/condos' },
    { label: '100 Newest Homes', link: '/new-100/homes-100' },
    { label: '100 Most Expensive', link: '/new-100/expensive' },

];

const moreItems = [
    { label: 'New Development', link: '/pre-construction' },
    { label: 'Subscribe', link: '/subscribe' },
    { label: 'Blogs', link: '/blog' },
    { label: 'Sell my property', link: '/sell-properties' },
    { label: 'Contact ', link: '/contact' },
];

const MobileNavBar = ({ setIsScrolled, isScrolled, propertyType, isOpen, setIsOpen, setShowAccount }) => {
    const [searchInput, setSearchInput] = useState('');
    const { data, setKeyData } = useData();

    const [isSearched, setIsSearched] = useState(false);

    const toggleNav = () => {
        setIsScrolled(true)
        setIsOpen(!isOpen);
        setShowAccount(false);
    };
    const closeNav = () => {
        if (window.scrollY > 50) {
            setIsScrolled(true);
            setIsOpen(false);
            setShowAccount(false);
            
        } else {
            setIsScrolled(false);
            setIsOpen(false);
            setShowAccount(false);
        }
        setIsOpen(!isOpen);
    };


    const handleSearch = () => {
        setIsSearched(!isSearched);
        setIsOpen(false)
        setShowAccount(false)
        if (window.scrollY > 50) {
            setIsScrolled(true);
            setIsOpen(false);
            setShowAccount(false);
            
        } else {
            setIsScrolled(false);
            setIsOpen(false);
            setShowAccount(false);
        }
    };

    const isLogin = data?.isLogin
    const handleLogout = () => {
        localStorage.removeItem('user_data')
        setKeyData('isLogin', null)
    }

    const tokenCheck = async () =>{
        const token = JSON.parse(localStorage.getItem('user_data'))?.token; // Retrieve the token from localStorage
        const headers = {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        };
    
        try {
                await axios.get(
                    `${API_BASE_URL}domainparmeter/check-token`,
                    { headers } // Pass headers with the request
                );
            
        } catch (error) {
            console.log(error?.response);
            
            if(error?.response?.data?.message === "Unauthenticated."){
                handleLogout()
            }
            console.error('Error toggling wishlist:', error?.response.data.message);
        }
    }
    useEffect(()=>{
        const token = localStorage.getItem('user_data');
if(token){

    tokenCheck()
}
    }, [])
    
    return (
        <>
            <AnimatePresence>
                {isSearched && (
                    <motion.nav
                        initial={{ opacity: 0, y: '-100%' }}
                        animate={{ height: '120px', opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: '-100%' }}
                        transition={{ duration: 0.5 }}
                        className="fixed inset-0 bg-white text-white z-[100000] w-full top-0 flex justify-center items-center p-10"
                    >
                        <div className="flex-1 w-full flex gap-4 items-center">
                            <div className="flex-1">

                                <div className={`flex justify-between  px-4 py-2  text-theme_yellow bg-white border border-theme_yellow rounded-full`}>
                                <AutoCompleteSearch style={{
                                search: ` ${'bg-white text-theme_yellow border-theme_yellow'} rounded-l-full `,
                                type: ` ${'bg-white text-theme_yellow'}`,
                                btn: ` text-[30px] ${'text-theme_yellow'}`,
                            }} />
                                </div>
                            </div>
                            <div>
                                <Button
                                    className={'rounded-full'}
                                    onClick={handleSearch}
                                    title={<ImCross />}
                                />
                            </div>
                        </div>
                    </motion.nav>
                )}
            </AnimatePresence>

            <div className="relative">

                <div className="flex items-center gap-2 md:gap-4">
                    {/* <AuthModal> 
                         <div className={`p-2 text-base rounded-full flex justify-center items-center  ${isScrolled ? 'bg-theme_blue text-white' : " bg-white text-black"}`}><FaUser /></div>
                    </AuthModal> */}
                    {/* {isLogin ? 
                        <LoggedInUser isScrolled={isScrolled} name={isLogin?.first_name?.toUpperCase()} handleLogout={handleLogout} /> :
                                <AuthModal ><div style={{width:35, height:35}} className={`p-3 text-lg rounded-full flex justify-center items-center cursor-pointer  ${isScrolled ? 'bg-theme_blue text-white' : " bg-white text-black"}`}><FaUser /></div></AuthModal>
                            } */}
                    <div
                        onClick={handleSearch}
                        className={`p-2 md:p-4 ${isScrolled
                            ? 'text-white bg-theme_yellow border-theme_yellow'
                            : 'text-theme_yellow border-white bg-white'
                            } rounded-full border`}
                    >
                        <IoSearch className="text-[16px] md:text-[32px]" />
                    </div>
                    <button
                        className={`p-2 md:p-4 ${isScrolled
                            ? (isOpen ? 'text-white bg-theme_yellow border-theme_yellow' : 'text-theme_yellow border-theme_yellow')
                            : 'text-white border-white mr-4'
                            } rounded-full border m-1`}
                        onClick={isOpen ? closeNav : toggleNav}
                    >
                                            {isOpen ? <IoClose className="text-base md:text-3xl" /> : <GiHamburgerMenu className="text-base md:text-3xl" />}

                    </button>


               
                </div>
    
            </div>
        </>
    );
};


const Navbar = () => {
    const [searchInput, setSearchInput] = useState('');
    const navigate = useNavigate();
    const handleSearch = () => {
        if (searchInput.trim()) {
            navigate('/miami/on-filter', { state: { filter: searchInput } });
        }
    };
    const [isOpen, setIsOpen] = useState(false);
    const [showAccount, setShowAccount] = useState(false);

    const [isScrolled, setIsScrolled] = useState(false);
    const [moreOpen, setMoreOpen] = useState(false);
    const [top100Open, setTop100Open] = useState(false);
    const location = useLocation()
    const [activeDropdown, setActiveDropdown] = useState(null);
    const { data, setKeyData } = useData();

    const isLogin = data?.isLogin
    const handleMouseEnter = (dropdownName) => {
        setActiveDropdown(dropdownName);
    };

    const handleMouseLeave = () => {
        setActiveDropdown(null);
    };
    const handleLogout = () => {
        localStorage.removeItem('user_data')
        setKeyData('isLogin', null)
    }


    const showModal = (title) => {
setIsOpen(false)        
setShowAccount(false)
        setKeyData('loginModalTitle', title);
        setKeyData('isLoginModalOpen', true);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
                setIsOpen(false);
                setShowAccount(false);

            } else {
                setIsScrolled(false);
                setIsOpen(false);
                setShowAccount(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const isActive = (path) => location.pathname.split('/').includes(path) ? 'border-b border-b-[3px] border-b-theme_yellow before:absolute before:content-none before:scale-x-100 ' : '';
    return (
        <div className={`w-full flex justify-center`}>



            <nav className={`p-4 pr-0 top-0 transition-all duration-300 ${isScrolled ? (isOpen ? 'bg-white shadow-lg fixed w-[90%] px-5 py-3 z-[11000] rounded-tr-[35px] rounded-br-[35px] rounded-bl-[35px] rounded-tl-[35px]  top-3' : 'bg-white shadow-lg fixed w-[90%] px-5 py-3 z-[11000]   top-3  rounded-tr-[45px] rounded-br-[45px] rounded-bl-[45px] rounded-tl-[45px]') : 'bg-transparent w-full '}`}>
                <div className="pl-[10px]  mx-auto flex gap-4 justify-between items-center">
                    <div className={`font-bold text-xl transition-all duration-300 ${isScrolled ? 'text-theme_yellow ' : 'text-white'}`}>
                        <Link to="/"><img src={isScrolled ? sec3logo : logo} alt="logo" className={` ${isScrolled ? 'max-h-[50px] max-[600px]:w-[100px]' : 'max-[600px]:w-[120px] max-[600px]:max-h-[70px]'}`} /></Link>
                    </div>
                    <div className={`flex items-center text-[16px] xl:text-[25px] text-nowrap space-x-2 ${isScrolled ? ' pr-4 ' : ''}`}>
                        <ul className={`hidden md:flex gap-2 xl:space-x-4 items-center pr-4 ${isScrolled ? 'text-theme_yellow' : 'text-white'}`}>
                            < li ><Link to="/Condos" className={`${isActive('Condos')} nav-item`}>Condos</Link></li>
                            < li ><Link to="/homes" className={`${isActive('homes')} nav-item`}>Homes</Link></li>
                            < li ><Link to="/map" className={`${isActive('map')} nav-item`}>Map</Link></li>
                            < li ><Link to="/about" className={`${isActive('about')} nav-item`}>About</Link></li>
                            <li
                                className="relative"
                                onMouseEnter={() => handleMouseEnter('top100')}
                                onMouseLeave={handleMouseLeave}
                            >
                                <Link to={top100Items[0] ? top100Items[0].link : "/new-100/listing"} className={`${isActive('new-100')} nav-item`}>Top 100</Link>
                                {activeDropdown === 'top100' && <DropdownMenu items={top100Items ?? []} isScrolled={isScrolled} />}
                            </li>
                            <li
                                className="relative"
                                onMouseEnter={() => handleMouseEnter('more')}
                                onMouseLeave={handleMouseLeave}
                            >
                                <Link to="#blog" className="nav-item">More</Link>
                                {activeDropdown === 'more' && <DropdownMenu items={moreItems} isScrolled={isScrolled} />}
                            </li>
                            {isLogin ? <li>{<LoggedInUser isScrolled={isScrolled} name={isLogin?.first_name?.toUpperCase()} handleLogout={handleLogout} />}</li> :
                                < li ><AuthModal ><div className={`p-3 text-lg rounded-full flex justify-center items-center cursor-pointer  ${isScrolled ? 'bg-theme_blue text-white' : " bg-white text-black"}`}><FaUser /></div></AuthModal></li>
                            }</ul>
                        <div className={`hidden md:flex  text-[14px] justify-between items-center px-2 rounded-l-full ${isScrolled ? 'bg-theme_yellow rounded-full' : 'bg-white'} transition-all duration-300 `}>
                            <AutoCompleteSearch style={{
                                search: ` ${isScrolled ? 'bg-inherit text-white placeholder:text-white placeholder:opacity-75' : 'bg-white text-theme_yellow'} rounded-l-full `,
                                type: ` ${isScrolled ? 'bg-inherit text-white placeholder:text-white placeholder:opacity-75' : 'bg-white text-theme_yellow'}`,
                                btn: ` text-[30px] ${isScrolled ? 'text-white placeholder:text-white placeholder:opacity-75' : 'text-theme_yellow'}`,
                            }} /> </div>
                        <div className="  md:hidden">
                            <MobileNavBar setShowAccount={setShowAccount} setIsScrolled={setIsScrolled} isScrolled={isScrolled} propertyType={data?.propertyType ?? []} isOpen={isOpen} setIsOpen={setIsOpen} />
                        </div>
                    </div>
                </div>

                {isOpen && (
    <AnimatePresence>
        <div className="mt-5 px-[10px] ">

        <p className="text-[14px] tracking-[1px]">Call us</p>
        <a href="tel:+13059886068"> <p className="text-[16px] creato-f-r text-theme_yellow">(305) 988-LUXLIFE</p></a>
        </div>
  {isLogin ? <div className="flex gap-4 mt-4 justify-center">
 
                              <button onClick={()=>setShowAccount(!showAccount)}
                                className='py-[6px] tracking-[1px] rounded-[22px] px-10 creato-f-r btn-primary  p-4 bg-theme_yellow text-white rounded-2xl text-nowrap'
                            >My Account</button>
  </div> : <div className="flex gap-4 mt-4 justify-center">
  <button onClick={()=>showModal('Sign in')}
                                className='py-[6px] tracking-[1px] rounded-[22px] px-10 creato-f-r btn-primary  p-4 bg-theme_yellow text-white rounded-2xl text-nowrap'
                            >Login</button>

             
<button onClick={()=>showModal('Sign up')}
                                className='py-[6px] tracking-[1px] rounded-[22px] px-10 creato-f-r btn-primary  p-4 bg-theme_yellow text-white rounded-2xl text-nowrap'
                            >Sign up</button>

             
                </div>}

            {showAccount ?
            <ul className="flex flex-col my-5 gap-2 text-left w-full px-[10px] ">
                <FiChevronRight onClick={()=>setShowAccount(false)}
      size={22}
      className={`text-xl text-[#022541] mr-[10px] transform transition-transform duration-300 rotate-180`}
    />
            <li className="ml-5">
                <Link to="/profile" className="text-lg creato-f-m text-theme_yellow flex flex-row items-center">
                <CgProfile className="mr-2" size={22} /> My Profile
                </Link>
            </li>
           
            <li className="ml-5">
                <Link to="/saved-properties" className="text-lg creato-f-m text-theme_yellow flex flex-row items-center">
                <IoMdHeart className="mr-2" size={22} /> Saved Properties
                </Link>
            </li>
           
            <li className="ml-5">
                <Link onClick={handleLogout} className="text-lg creato-f-m text-theme_yellow flex flex-row items-center">
                <CiLogin className="mr-2" size={22} /> Logout
                </Link>
            </li>
           
            
            </ul> : <ul className="flex flex-col mt-3 gap-2 text-left w-full px-[10px] ">
                <li>
                    <Link to="/condos" className="text-xl creato-f-m text-theme_yellow">
                        Condos
                    </Link>
                </li>
                <li>
                    <Link to="/homes" className="text-xl creato-f-m text-theme_yellow">
                        Homes
                    </Link>
                </li>
                <li>
                    <Link to="/map" className="text-xl creato-f-m text-theme_yellow">
                        Map
                    </Link>
                </li>
                <li>
                    <Link to="/about" className="text-xl creato-f-m text-theme_yellow">
                        About
                    </Link>
                </li>
               <li>
   <div className="flex justify-between items-center cursor-pointer"
    onClick={() => {
        setTop100Open(!top100Open);
      setMoreOpen(false);
    }}
  >
    <p className="text-xl creato-f-m text-theme_yellow">Top 100</p>
    <FiChevronRight
      size={22}
      className={`text-xl text-theme_yellow mr-[10px] transform transition-transform duration-300 ${
        top100Open ? 'rotate-90' : 'rotate-0'
      }`}
    />
  </div>

  <div
    className={`transition-all mt-1 flex flex-col gap-1 duration-300 ease-in-out overflow-hidden ${
        top100Open ? 'max-h-96 opacity-100 mb-2' : 'max-h-0 opacity-0'
    }`}
    style={{ maxHeight: top100Open ? '500px' : '0px' }}
  >
    <Link
      to="/new-100/listing"
      className="text-base creato-f-m ml-3 text-[#e4a232b3]"
    >
      100 Newest Listings
    </Link>
    <Link
      to="/new-100/penthouses"
      className="text-base creato-f-m ml-3 text-[#e4a232b3]"
    >
      100 Newest Penthouses
    </Link>
    <Link
      to="/new-100/condos"
      className="text-base creato-f-m ml-3 text-[#e4a232b3]"
    >
      100 Newest Condos
    </Link>
    <Link
      to="/new-100/homes-100"
      className="text-base creato-f-m ml-3 text-[#e4a232b3]"
    >
      100 Newest Homes
    </Link>
    <Link
      to="/new-100/expensive"
      className="text-base creato-f-m ml-3 text-[#e4a232b3]"
    >
      100 Newest Expensive
    </Link>

  </div>
</li>



                <li>
  <div
    className="flex justify-between items-center cursor-pointer"
    onClick={() => {
      setMoreOpen(!moreOpen);
      setTop100Open(false);
    }}
  >
    <p className="text-xl creato-f-m text-theme_yellow">More</p>
    <FiChevronRight
      size={22}
      className={`text-xl text-theme_yellow mr-[10px] transform transition-transform duration-300 ${
        moreOpen ? 'rotate-90' : 'rotate-0'
      }`}
    />
  </div>

  <div
    className={`transition-all mt-1 flex flex-col gap-1 duration-300 ease-in-out overflow-hidden ${
      moreOpen ? 'max-h-96 opacity-100 mb-2' : 'max-h-0 opacity-0'
    }`}
    style={{ maxHeight: moreOpen ? '500px' : '0px' }}
  >
    <Link
      to="/pre-construction"
      className="text-base creato-f-m ml-3 text-[#e4a232b3]"
    >
      New Development
    </Link>
    <Link
      to="/subscribe"
      className="text-base creato-f-m ml-3 text-[#e4a232b3]"
    >
      Subscribe
    </Link>
    <Link to="/blog" className="text-base creato-f-m ml-3 text-[#e4a232b3]">
      Blog
    </Link>
    <Link
      to="/sell-properties"
      className="text-base creato-f-m ml-3 text-[#e4a232b3]"
    >
      Sell my property
    </Link>
    <Link to="/contact" className="text-base creato-f-m ml-3 text-[#e4a232b3]">
      Contact
    </Link>
  </div>
</li>

              
               
                <li>
                    <Link to="/contact" className="text-xl creato-f-m text-theme_yellow">
                        Contact Us
                    </Link>
                </li>
            </ul>}



            <div className="flex flex-row items-center mt-4">
            <img
                src={agent} // Replace with actual image URL
                alt="Daniel Pansky"
                className="w-20 rounded-full border-4 border-white "
            />
                <div className="ml-1">
                    <p className="text-theme_yellow creato-f-r text-[20px]">Daniel Pansky</p>
                    <p className="text-[14px]">LuxLife RE Agent/Owner</p>
                    <p className="text-base creato-f-r">Call or Text Me Anytime</p>
                    <div className="flex gap-1 mt-1">
                    <a className='w-full' target='_blank' href="https://wa.link/q26x4b">

  <button
                                className={'btn-primary  px-2 bg-theme_yellow text-white rounded-2xl text-nowrap py-[6px] text-[13px] tracking-[1px] rounded-lg creato-f-r'}
                            ><div className="flex items-center justify-center gap-1 text-white   ">
                            <WhatsAppOutlined />
                            WhatsApp
                        </div></button>
                            </a>
                            <a className='w-full' href="mailto:dpansky@luxlifemiamirealestate.com">



  <button
                                className={'btn-primary  px-2 bg-theme_yellow text-white rounded-2xl text-nowrap py-[6px] text-[13px] tracking-[1px] rounded-lg creato-f-r'}
                            ><div className="flex items-center justify-center gap-1 text-white   ">
                            <MailOutlined />
                            Send Me Info
                        </div></button>
             </a>
                </div>
                </div>
            </div>

            <p className="text-theme_yellow creato-f-r text-[11px] mr-[20px] text-center mt-5 mb-1">&copy; 2024 Luxlife Miami Real Estate LLC. All rights reserved</p>
    </AnimatePresence>
)}


            </nav>
        </div>
    );
}

export default Navbar;
