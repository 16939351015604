import axios from "axios"
import { API_BASE_URL } from "../../config"

const token = JSON.parse(localStorage.getItem('user_data'))?.token;

export const getHomeProperties = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}listings?limit=15&sortBy=OriginalListPrice&order=desc&OriginalListPrice.gte=1000000&PropertySubType.in=Condominium&PropertySubType.in=Apartment&PropertySubType.in=Single Family Residence&PropertySubType.in=Townhouse&PropertySubType.in=Residential&PropertySubType.in=Villa&PropertySubType.in=Multi Family&PropertySubType.in=Income/MultiFamily&PropertySubType.in=Duplex&PropertySubType.in=TownHouse&ListingAgreement=Exclusive Right To Sell`);

        return {
            data: {
                ...response.data,
                data: response.data.data?.filter(v => v.ListingID)
            }
        }


    } catch (error) {

    }

}
export const getProperties = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}listings?sortBy=OnMarketDate&order=desc&OriginalListPrice.gte=1000000`);

        return {
            data: {
                ...response.data,
                data: response.data.data?.filter(v => v.ListingID)
            }
        }


    } catch (error) {

    }

}
export const getCondos = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}listings?sortBy=OnMarketDate&order=desc&PropertySubType.in=Condominium&PropertySubType.in=Apartment&limit=50&OriginalListPrice.gte=1000000&ListingAgreement=Exclusive Right To Sell`);
        return {
            data: {
                ...response.data,
                data: response.data.data?.filter(v => v.ListingID)
            }
        }


    } catch (error) {

    }

}
export const getHome = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}listings?sortBy=OnMarketDate&order=desc&PropertySubType.in=Single Family Residence&PropertySubType.in=Townhouse&PropertySubType.in=Residential&PropertySubType.in=Villa&PropertySubType.in=Multi Family&PropertySubType.in=Income/MultiFamily&PropertySubType.in=Duplex&PropertySubType.in=TownHouse&limit=50&OriginalListPrice.gte=1000000&ListingAgreement=Exclusive Right To Sell`);
        return {
            data: {
                ...response.data,
                data: response.data.data?.filter(v => v.ListingID)
            }
        }


    } catch (error) {

    }

}

export const getListingPropertyData = async (query) => {
    try {
        const response = await axios.get(`${API_BASE_URL}listings?${query}&sortBy=OnMarketDate&order=desc&ListingAgreement=Exclusive Right To Sell&PropertySubType.in=Condominium&PropertySubType.in=Apartment&PropertySubType.in=Single Family Residence&PropertySubType.in=Townhouse&PropertySubType.in=Residential&PropertySubType.in=Villa&PropertySubType.in=Multi Family&PropertySubType.in=Income/MultiFamily&PropertySubType.in=Duplex&PropertySubType.in=TownHouse`);

        return {
            data: {
                ...response.data,
                data: response.data.data?.filter(v => v.ListingID)
            }
        }


    } catch (error) {

    }

}
export const getListingReferencePropertyData = async (query) => {
    try {
        const response = await axios.get(`${API_BASE_URL}listings?${query}&limit=3&sortBy=OnMarketDate&order=desc&OriginalListPrice.gte=1000000&ListingAgreement=Exclusive Right To Sell`);

        return {
            data: {
                ...response.data,
                data: response.data.data?.filter(v => v.ListingID)
            }
        }


    } catch (error) {

    }

}
export const getListingData = async (query, onlyQuery) => {
    try {
        const response = await axios.get(onlyQuery ? `${API_BASE_URL}listings?${query}` : `${API_BASE_URL}listings?${query}&PropertySubType.in=Apartment&PropertySubType.in=Single Family Residence&PropertySubType.in=Townhouse&PropertySubType.in=Residential&PropertySubType.in=Villa&PropertySubType.in=Multi Family&PropertySubType.in=Income/MultiFamily&PropertySubType.in=Duplex&PropertySubType.in=TownHouse`);

        return {
            data: {
                ...response.data,
                data: response.data.data?.filter(v => v.ListingID)
            }
        }

    } catch (error) {

    }

}
export const getActiveListingData = async (query) => {
    try {
        const response = await axios.get(`${API_BASE_URL}listings?${query}&OriginalListPrice.gte=1000000`);

        return {
            data: {
                ...response.data,
                data: response.data.data?.filter(v => v.ListingID)
            }
        }

    } catch (error) {

    }

}
export const getBuildingExclusiveListingData = async (query) => {
    try {
        const response = await axios.get(`${API_BASE_URL}Exclusiveslistings?StandardStatus=Active${query}&OriginalListPrice.gte=1000000`);

        return {
            data: {
                ...response.data,
                data: response.data.data?.filter(v => v.ListingID)
            }
        }

    } catch (error) {

    }

}
export const getExclusiveListingData = async (query) => {
    try {
        const response = await axios.get(`${API_BASE_URL}Exclusiveslistings?${query}&PropertySubType.in=Condominium&PropertySubType.in=Apartment&PropertySubType.in=Single Family Residence&PropertySubType.in=Townhouse&PropertySubType.in=Residential&PropertySubType.in=Villa&PropertySubType.in=Multi Family&PropertySubType.in=Income/MultiFamily&PropertySubType.in=Duplex&PropertySubType.in=TownHouse&UnparsedAddress.in=Aventura&UnparsedAddress.in=Bal%2BHarbour&UnparsedAddress.in=Bay%2BHarbor%2BIslands&UnparsedAddress.in=Bay%2BHarbour&UnparsedAddress.in=Boca%2BRaton&UnparsedAddress.in=Coconut%2BGrove&UnparsedAddress.in=Coral%2BGables&UnparsedAddress.in=Cutler%2BBay&UnparsedAddress.in=Doral&UnparsedAddress.in=Edgewater&UnparsedAddress.in=Fisher%2BIsland&UnparsedAddress.in=Fort%2BLauderdale&UnparsedAddress.in=Golden%2BBeach&UnparsedAddress.in=Hallandale%2BBeach&UnparsedAddress.in=Hibiscus%2BDr&UnparsedAddress.in=Hollywood&UnparsedAddress.in=Indian%2BCreek%2BDr&UnparsedAddress.in=Pine%2BTree%2BDr&UnparsedAddress.in=Key%2BBiscayne&UnparsedAddress.in=La%2BGorce%2BDr&UnparsedAddress.in=Manalapan&UnparsedAddress.in=Miami&UnparsedAddress.in=Miami%2BBeach&UnparsedAddress.in=Miami%2BGardens&UnparsedAddress.in=Miami%2BLakes&UnparsedAddress.in=Miami%2BShores&UnparsedAddress.in=North%2BBay%2BVillage&UnparsedAddress.in=North%2BMiami&UnparsedAddress.in=North%2BMiami%2BBeach&UnparsedAddress.in=Palm%2BBeach%2BCounty&UnparsedAddress.in=Pinecrest&UnparsedAddress.in=Pompano%2BBeach&UnparsedAddress.in=South%2BMiami&UnparsedAddress.in=Sunny%2BIsles%2BBeach&UnparsedAddress.in=Star%2BIsland&UnparsedAddress.in=Surfside&UnparsedAddress.in=Venetian%2BDr&UnparsedAddress.in=West%2BPalm%2BBeach&UnparsedAddress.in=Weston`);

        return {
            data: {
                ...response.data,
                data: response.data.data?.filter(v => v.ListingID)
            }
        }

    } catch (error) {

    }

}
export const getListingExpensiveData = async (query) => {
    try {
        const response = await axios.get(`${API_BASE_URL}listings?${query}&limit=100&sortBy=ListPrice&order=desc&PropertySubType.in=Condominium&PropertySubType.in=Apartment&PropertySubType.in=Single Family Residence&PropertySubType.in=Townhouse&PropertySubType.in=Residential&PropertySubType.in=Villa&PropertySubType.in=Multi Family&PropertySubType.in=Income/MultiFamily&PropertySubType.in=Duplex&PropertySubType.in=TownHouse&ListingAgreement=Exclusive Right To Sell`);

        return {
            data: {
                ...response.data,
                data: response.data.data?.filter(v => v.ListingID)
            }
        }


    } catch (error) {

    }

}
export const getListingHomeData = async (query) => {
    try {
        const response = await axios.get(`${API_BASE_URL}listings?${query}&sortBy=OnMarketDate&order=desc&PropertySubType.in=Single Family Residence&PropertySubType.in=Townhouse&PropertySubType.in=Residential&PropertySubType.in=Villa&PropertySubType.in=Multi Family&PropertySubType.in=Income/MultiFamily&PropertySubType.in=Duplex&PropertySubType.in=TownHouse&ListingAgreement=Exclusive Right To Sell`);

        return {
            data: {
                ...response.data,
                data: response.data.data?.filter(v => v.ListingID)
            }
        }


    } catch (error) {

    }

}
export const getListingCondosData = async (query) => {
    try {
        const response = await axios.get(`${API_BASE_URL}listings?${query}&sortBy=OnMarketDate&order=desc&PropertySubType.in=Condominium&PropertySubType.in=Apartment&ListingAgreement=Exclusive Right To Sell`);

        return {
            data: {
                ...response.data,
                data: response.data.data?.filter(v => v.ListingID)
            }
        }


    } catch (error) {

    }

}
export const getCustomListingByID = async (id) => {
    try {
        const response = await axios.get(`${API_BASE_URL}Exclusivelistings/${id}`);

        return response

    } catch (error) {

    }

}
export const getListingVillaData = async (query) => {
    try {
        const response = await axios.get(`${API_BASE_URL}listings?${query}&sortBy=OnMarketDate&order=desc&PropertySubType=Condominium&ArchitecturalStyle=Penthouse`);


        return {
            data: {
                ...response.data,
                data: response.data.data?.filter(v => v.ListingID)
            }
        }

    } catch (error) {

    }

}
export const getListingByID = async (id) => {
    try {
        const response = await axios.get(`${API_BASE_URL}listings/${id}`);

        return response

    } catch (error) {

    }

}

export const getPropertyTypes = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}get-property-sub`);

        return response

    } catch (error) {

    }

}
export const getAllCities = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}get-cities`);

        return response

    } catch (error) {

    }
}
export const getSinglePreConstruction = async (id) => {
    try {
        const response = await axios.get(`${API_BASE_URL}getPreConstruction/${id}`);

        return response

    } catch (error) {

    }
}
export const getSingleBuilding = async (id) => {
    try {
        const response = await axios.get(`${API_BASE_URL}import-buildings/bynames/${id}`);

        return response

    } catch (error) {

    }
}
export const getDefaultSearchData = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}Searchlistings`);

        return response

    } catch (error) {

    }
}