import React, { useState } from 'react';
import bed from '../../assets/images/bed.png';
import bath from '../../assets/images/bath.png';
import area from '../../assets/images/area.png';
import { FaLocationDot } from 'react-icons/fa6';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import Button from '../buttons/Button';
import { Link } from 'react-router-dom';
import Image from '../../assets/images/android-chrome-512x512.png';
import { isLoginHandle } from '../../lib/helper';
import { useData } from '../../context/DataContext';

const Card = ({ data, className, property_url, toggleWishlist }) => {
    const [isSaved, setIsSaved] = useState(data?.is_saved === 1);
    const [loading, setLoading] = useState(false);
    const { setKeyData } = useData()

    const handleCardClick = (ListingID) => {
        if (!isLoginHandle()) {
            setKeyData('isLoginModalOpen', true);
            return;
        }
    
        const existingIds = JSON.parse(localStorage.getItem('listingIds')) || [];
    
        if (!existingIds.includes(ListingID)) {
            existingIds.push(ListingID);
            localStorage.setItem('listingIds', JSON.stringify(existingIds));
        }
    };
    

    return (
        <div
            className={`w-[350px] my-4 max-h-[max-content] min-h-[20vw] rounded-2xl overflow-hidden black_white_effect2 group cursor-pointer transition duration-300 ${className}`}
        >
            <div className="relative">
                <Link to={isLoginHandle() ?  
       (data?.is_custom
        ? `/custom-single-property/${data?.ListingID}` 
        : `/single-property/${data?.ListingID}`) 
       : '#'}  onClick={handleCardClick}>
                    <div
                        className="h-[220px] lg:h-[11.5vw] overflow-hidden"
                        style={{ background: '#fff' }}
                    >
                        <img
                            src={
                                data?.Photos && data?.Photos[0]?.MediaURL
                                    ? data?.Photos[0]?.MediaURL
                                    : Image
                            }
                            alt=""
                            width={'100%'}
                            className="h-[220px] lg:h-[11.5vw] transition duration-300 min-w-[350px]"
                            style={
                                data?.Photos && data?.Photos[0]?.MediaURL
                                    ? { objectFit: 'cover' }
                                    : { objectFit: 'contain' }
                            }
                        />
                    </div>
                </Link>
                {/* Wishlist Icon */}
                <button
                    onClick={() => isLoginHandle() ? toggleWishlist(loading, setLoading, isSaved, setIsSaved, data?.ListingID) : handleCardClick()}
                    disabled={loading}
                    className="absolute top-2 right-2 bg-white p-2 rounded-full shadow-md z-10"
                >
                    {isSaved ? (
                        <AiFillHeart className="text-red-500 text-lg" />
                    ) : (
                        <AiOutlineHeart className="text-gray-500 text-lg" />
                    )}
                </button>

                {data?.is_exclusive === 1 && <div className="absolute top-2 left-2 md:flex bg-yellow-500 text-white px-2 py-1 text-xs font-bold rounded">Exclusive
</div>}
            </div>

            <div className="bg-white p-[5%] rounded-b-2xl">
                <div className="md:text-[18px] xl:text-[1.2vw] flex justify-between poppins-bold text-theme_yellow font-semibold text-start mb-2 leading-[27px] xl:leading-[1.6vw]">
                    <Link  to={isLoginHandle() ?  
       (data?.is_custom
        ? `/custom-single-property/${data?.ListingID}` 
        : `/single-property/${data?.ListingID}`) 
       : '#'}  onClick={()=>handleCardClick(data?.ListingID)}>
                        <p className='text-md'>
                            {data?.ListingInfo?.StreetAddress?.Address ? data?.ListingInfo?.StreetAddress?.Address.split(",")[0] : (data?.ListingInfo?.StreetAddress?.StreetNumber + ' ' + data?.ListingInfo?.StreetAddress?.StreetName + ' ' + data?.ListingInfo?.Location?.State)}
                  
                        </p>
                    </Link>
                    <p className="text-[12px] text-green-500 sm:hidden">
                        {data?.ListingInfo?.MLSDetails?.StandardStatus}
                    </p>
                </div>
                <div className="flex flex-col justify-between gap-2 flex-wrap poppins-regular">
                    <div className="flex justify-between">
                        <div className="flex items-center gap-2">
                            <img src={bed} alt="" className="w-5 lg:w-[1vw]" />{' '}
                            <p className="text-[#101010] text-sm text-nowrap leading-[18px]">
                                {data?.PropertyDetails?.Bedrooms} Bed
                            </p>
                            <img src={bath} alt="" className="w-5 lg:w-[1vw]" />{' '}
                            <p className="text-[#101010] text-sm text-nowrap leading-[18px]">
                                {data?.PropertyDetails?.BathroomsTotal} Bath
                            </p>
                            <img src={area} alt="" className="w-5 lg:w-[1vw]" />{' '}
                            <p className="text-[#101010] text-sm text-nowrap leading-[18px]">
                                {data?.PropertyDetails?.LivingArea} SQFT
                            </p>
                        </div>
                        <div className="hidden sm:block">
                        <p className="text-sm text-green-500" style={{textAlign:"end"}}>
                        {data?.ListingInfo?.MLSDetails?.StandardStatus}
                            </p>
                        </div>
                    </div>
                    <div className="flex items-center gap-1">
                        <FaLocationDot className="text-[13px] lg:text-[0.7vw]" />{' '}
                        <p className="text-[#101010] text-xs text-nowrap leading-[18px]">
                            {data?.ListingInfo?.StreetAddress?.Address || (data?.ListingInfo?.StreetAddress?.StreetNumber + ' ' + data?.ListingInfo?.StreetAddress?.StreetName + ' ' + data?.ListingInfo?.Location?.State)}
                        </p>
                    </div>
                </div>

                <div className="flex mt-[4%] justify-between items-center">
                    <p className="text-lg md:text-[1.2vw] poppins-bold ">
                        $
                        {data?.ListingInfo?.PriceInfo?.OriginalListPrice
                            ? String(Number(data?.ListingInfo?.PriceInfo?.OriginalListPrice).toLocaleString())
                            : data?.ListingInfo?.PriceInfo?.ListPrice?.toLocaleString()}
                    </p>
                    {!property_url ? (
                        <div>
                            <Button
                                title={'Book Now'}
                                className={'py-[9px] max-w-[95px] text-[13px] rounded-xl poppins-semibold'}
                            />
                        </div>
                    ) : (
                        
                        <Link to={isLoginHandle() ?  
                            (data?.is_custom
                            ? `/custom-single-property/${data?.ListingID}` 
                            : `/single-property/${data?.ListingID}`) 
                            : '#'}  onClick={handleCardClick}>
                            <Button
                                title={'Book Now'}
                                className={'py-[9px] max-w-[95px] text-[13px] rounded-xl poppins-semibold'}
                                />
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Card;
